import request from '@/utils/request'

// 查询视频/微课列表
export function getVideoList (params) {
  return request({
    url: '/api/videos/module/videos/listEnterprise',
    method: 'get',
    params
  })
}
// 查询视频/微课列表
export function getVideoDetails (id) {
  return request({
    url: `/api/videos/app/videos/getInfoEnterprise/${id}`,
    method: 'get'
  })
}

// 分页查询视频合集
export function getVideoListByPage (params) {
  return request({
    url: '/api/Album/VideosAlbumController/queryPageByCondition',
    method: 'get',
    params
  })
}

// 查询单个视频合集
export function getVideoListById (params) {
  return request({
    url: '/api/Album/VideosAlbumController/queryOneByCondition',
    method: 'get',
    params
  })
}

// 查询分类
export function getTreeByType (params) {
  return request({
    url: '/api/plat/employmentLearn/platCategoryController/findTreeByType',
    method: 'get',
    params
  })
}

// 分页查询视频合集评论
export function getVideoPLList (params) {
  return request({
    url: '/api/comments/CommentsController/queryPageByCondition',
    method: 'get',
    params
  })
}

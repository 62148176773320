<template>
  <div class="ClassCard" :title="cardInfo.name">
    <div class="Img">
      <img
        :src="cardInfo.videoImageUrl"
        class="video-image"
        alt="图片加载错误"
      >
    </div>
    <div class="ClassTitle">
      <p class="title">{{ cardInfo.name }}</p>
      <div class="bottom">
        <div class="Type">{{ cardInfo.typeStr ? cardInfo.typeStr[0] : '' }}</div>
      </div>
      <div class="Titlecontent">
        视频数量：{{ cardInfo.modVideosDetailVOList ? cardInfo.modVideosDetailVOList.length : 0 }}
      </div>
      <div class="member">
        会员专享
      </div>
    </div>
  </div>
</template>

  <script>
  export default {
    props: {
      // 传入每一条的数据
      cardInfo: {
        type: Object,
        default: () => { }
      }
    },
    data () {
      return {

      }
    },
    methods: {

    }
  }
  </script>

  <style scoped lang="less">
  .ClassCard {
    width: 100%;
    border-radius: 8px;
    overflow: auto;
    position: relative;
    .Img {
      position: relative;
      width: 100%;
      display: inline-block;
      .video-image{
        display: inline-block;
        width: 100%;
        height: 126px;
        vertical-align: middle;
      }
      .Like {
        width: 100%;
        position: absolute;
        right: 0px;
        bottom: 0px;
        color: #fff;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 8px;
        height: 24px;
        background: linear-gradient(180deg, rgba(22, 119, 255, 0) 0%, #1677FF 100%);
        border-radius: 0px 0px 4px 4px;
        .likeNum{
          position: relative;
          padding-left: 15px;
          &::after{
            content: '';
            display: inline-block;
            width: 14px;
            height: 14px;
            position: absolute;
            left: 0;
            top: 3px;
            background: url('../../public/heart.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
    .ClassTitle {
      width: 100%;
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        display: inline;
        width: 100%;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 5px;
      }
      .Titlecontent {
        font-size: 14px;
        color: #aaaaaa;
        margin-top: 3px;
        width: 100%;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; /* 行数 */
        overflow: hidden;
      }
      .bottom {
        width: 100%;
        margin-top: 3px;
        .Type {
          font-size: 12px;
          display: inline-block;
          padding: 3px;
          background: rgba(22, 119, 255, 0.1);
          color: #1677FF;
        }
      }
    }
    .member {
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
      background: #4d72f7;
      border-radius: 0px 10px 0px 10px;
      text-align: center;
      font-size: 11px;
      padding: 3px 6px;
    }
  }
  </style>

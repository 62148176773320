<template>
  <div class="bidding__wrapper">
    <div class="nav-box">
      <tabNav :navList="navList" v-model="type" @input="handleTypeChange" />
      <a-cascader
        :options="typeOptions"
        style="width: 320px; margin-right: 24px;"
        placeholder="请选择分类"
        v-model="videoType"
        change-on-select
        allowClear
        :fieldNames="{
          label: 'name',
          value: 'id',
          children: 'list'
        }" />
      <a-input
        style="width: 320px; margin-right: 24px;"
        v-model="search"
        placeholder="请输入名称"
      />
      <a-button type="" @click="handleReset" >重置</a-button>
      <a-button type="primary" @click="onSearch">搜索</a-button>
    </div>
    <div class="content_list video-list" style="height: 730px; padding: 0 24px">
      <v-card-list
        ref="cardList"
        :getDataApi="getDataApi"
        :renderItem="renderItem"
        :initPagination="pagination"
        :colConfig="{
          span: 4,
        }"
      >
        <a-empty class="empty" slot="empty" />
      </v-card-list>
    </div>
  </div>
</template>

  <script>
  import videoItem from '@/components/videoItem.vue'
  import tabNav from '@/views/home/components/tab-nav.vue'

  import { getVideoListByPage, getTreeByType } from '@/api/video'
  export default {
    name: 'Index',
    components: {
        videoItem,
      tabNav
    },
    data () {
      return {
        // 搜索
        search: '',
        // 数据
        List: [],
        // 总数
        navList: [
          {
            label: '执业课程',
            value: '2'
          },
          {
            label: '技能微课',
            value: '3'
          }
        ],
        type: '2',
        pagination: {
          current: 1,
          pageSize: 15,
          total: 0,
          showQuickJumper: true,
          showSizeChanger: false,
          showTotal: (total) => `总数 ${total} 条`
        },
        aOptions: [],
        bOptions: [],
        videoType: []
      }
    },
    computed: {
        typeOptions () {
            return this.type == '2' ? this.aOptions : this.bOptions
        }
    },
    created () {
        getTreeByType({ type: 2 }).then(res => {
        this.aOptions = res
      })
      getTreeByType({ type: 3 }).then(res => {
        this.bOptions = res
      })
    },
    methods: {
      microClass (val) {
        window.open(`/video/details?id=${val.id}`)
      },
      handleReset () {
        this.search = ''
        this.videoType = []
        this.$refs.cardList.pageInit()
      },
      onSearch () {
        this.$refs.cardList.pageInit()
      },
      handleTypeChange (value) {
        this.type = value
        this.videoType = []
        this.$refs.cardList.pageInit()
      },
      getDataApi (params) {
        return getVideoListByPage({
          ...params,
          name: this.search,
          type: this.videoType ? this.videoType.join(',') : '',
          videoType: this.type,
          status: 0
        })
      },
      renderItem ({ item }) {
        return (
          <div class="list-item" onClick={this.microClass.bind(null, item)}>
            <videoItem cardInfo={item} />
          </div>
        )
      }
    }
  }
  </script>
  <style lang="less">
  @import url('../../common/style');
  </style>
  <style lang="less" scoped>
  .bidding__wrapper {
    .nav-box {
      height: 70px;
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .video-list {
      ::v-deep .ant-col  {
        width: 20% !important;
      }
    }
    .list-item {
      padding: 6px;
      cursor: pointer;
    }
  }
  </style>

<template>
  <div class="tab-nav">
    <div class="tab-nav-content">
      <div
        :class="{
          'nav-item': true,
          'transtion-hover': true,
          'nav-item-active': value === item.value
        }"
        @click="handleClick($event, item, index)"
        v-for="(item, index) in navList"
        :key="item.value"
      >{{ item.label }}</div>
    </div>
    <div class="tab-line transtion-hover" :style="lineStyle"></div>
  </div>
</template>

<script>
export default {
  name: 'TabNav',
  props: {
    navList: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    lineStyle () {
      return {
        transform: `translate(${this.translateX}px, 0px)`
      }
    }
  },
  data () {
    return {
      translateX: 0
    }
  },
  methods: {
    handleClick (e, { value }, index) {
      const { width } = e.target.getBoundingClientRect()
      this.translateX = (width + 25) * index
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="less">
.tab-nav {
  width: 100%;
  position: relative;
  height: 100%;
  .tab-nav-content {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    .nav-item {
      width: 70px;
      margin-right: 25px;
      font-weight: 550;
      font-size: 16px;
      color: #666666;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &-active {
        color: #1677ff;
      }
      &:hover {
        color: #1677ff;
      }
    }
  }
  .tab-line {
    position: absolute;
    bottom: 0px;
    transform: translate(60px, 0px);
    width: 70px;
    height: 4px;
    background: #1677ff;
  }
}
</style>
